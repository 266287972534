import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { Formik, useFormik } from "formik";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { useQueryState } from "react-router-use-location-state";
import { useParams } from "react-router";

const validationSchema = Yup.object().shape({
  code: Yup.string().required().min(6).max(6).label("Code"),
});

export default function OrderStickers() {
  let { code } = useParams();
  const receivedCode = code || "";
  const [submitted, setSubmitted] = useState(false);
  const [requestState, setRequestState] = useState(false);

  const orderStickers = (orderStickersObj, resetForm) => {
    var orderStickersUrl = `api/restaurant/orderstickers/${orderStickersObj.code}`;
    fetch(orderStickersUrl, {
      method: "POST",
      body: {},
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => {
        setSubmitted(true);
        if (response.ok) {
          setRequestState(true);
          resetForm({});
        } else {
          setRequestState(false);
        }
      })
      .catch((e) => {
        setRequestState(false);
      });
  };

  useEffect(() => {}, [setSubmitted]);

  const formik = useFormik({
    initialValues: {
      code: receivedCode,
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      orderStickers(values, resetForm);
    },
  });

  return (
    <div>
      <div className="container col-md-6">
        <div className="mb-3">
          {!submitted && (
            <form onSubmit={formik.handleSubmit}>
              <h4 className="h4">
                Once processed, we will send you free stickers which can be put
                on your tables, windows or door.
                <br />
                Your customers can scan these to see what to eat!
              </h4>
              <TextField
                fullWidth
                id="code"
                name="code"
                label="Code"
                value={formik.values.code}
                onChange={formik.handleChange}
                error={formik.touched.code && Boolean(formik.errors.code)}
                helperText={formik.touched.code && formik.errors.code}
                className="mb-3"
              />
              <Button
                className="btn btn-primary btn-block code"
                variant="contained"
                fullWidth
                type="submit"
              >
                Order Free Stickers
              </Button>
            </form>
          )}

          {submitted && requestState && (
            <h4 className="h4">
              Your request has been submitted. <br />
              We will be in touch soon to confirm delivery of your stickers.
            </h4>
          )}
          {submitted && !requestState && (
            <h4 className="h4">There was some problem</h4>
          )}
        </div>
      </div>
    </div>
  );
}
