import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { useQueryState } from "react-router-use-location-state";
import { useParams } from "react-router";

const validationSchema = Yup.object().shape({
  email: Yup.string().required().email("Invalid email").label("Email"),
  newPassword: Yup.string().required().min(8).label("New Password"),
  confirmNewPassword: Yup.string()
    .required()
    .min(8)
    .label("Confirm New Password")
    .oneOf([Yup.ref("newPassword"), null], "Passwords must match"),
});

export default function ResetPassword() {
  let { t } = useParams();
  const [thisIsT] = useQueryState("t", t);
  const [passwordSubmitted, setPasswordSubmitted] = useState(false);
  const [passwordResetSuccessful, setPasswordResetSuccessful] = useState(false);

  const resetPassword = (passwordResetObj, resetForm) => {
    var resetPasswordUrl = `${process.env.REACT_APP_API_URL}/users/resetPassword`;
    fetch(resetPasswordUrl, {
      method: "POST",
      body: JSON.stringify({
        t: thisIsT,
        ...passwordResetObj,
      }),
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => {
        setPasswordSubmitted(true);
        if (response.ok) {
          setPasswordResetSuccessful(true);
          resetForm({});
        } else {
          setPasswordResetSuccessful(false);
        }
      })
      .catch((e) => {
        setPasswordResetSuccessful(false);
      });
  };

  useEffect(() => {}, [setPasswordSubmitted]);

  const formik = useFormik({
    initialValues: {
      email: "",
      newPassword: "",
      confirmNewPassword: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      resetPassword(values, resetForm);
    },
  });

  return (
    <div>
      <div className="container col-md-6">
        <div className="mb-3">
          <form onSubmit={formik.handleSubmit}>
            <TextField
              fullWidth
              id="email"
              name="email"
              label="Email"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              className="mb-3"
            />
            <TextField
              fullWidth
              id="newPassword"
              name="newPassword"
              label="New Password"
              type="password"
              value={formik.values.newPassword}
              onChange={formik.handleChange}
              error={
                formik.touched.newPassword && Boolean(formik.errors.newPassword)
              }
              helperText={
                formik.touched.newPassword && formik.errors.newPassword
              }
              className="mb-3"
            />
            <TextField
              fullWidth
              id="confirmNewPassword"
              name="confirmNewPassword"
              label="Confirm New Password"
              type="password"
              value={formik.values.confirmNewPassword}
              onChange={formik.handleChange}
              error={
                formik.touched.confirmNewPassword &&
                Boolean(formik.errors.confirmNewPassword)
              }
              helperText={
                formik.touched.confirmNewPassword &&
                formik.errors.confirmNewPassword
              }
              className="mb-3"
            />
            <Button
              className="btn btn-primary btn-block code"
              variant="contained"
              fullWidth
              type="submit"
            >
              Submit
            </Button>

            {passwordSubmitted && passwordResetSuccessful && (
              <h4 className="h4">Your password has been changed</h4>
            )}
            {passwordSubmitted && !passwordResetSuccessful && (
              <h4 className="h4">There was some problem</h4>
            )}
          </form>
        </div>
      </div>
    </div>
  );
}
