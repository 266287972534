import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import PerfectScrollbar from "react-perfect-scrollbar";
import { makeStyles } from "@material-ui/styles";
import EditOutlined from "@material-ui/icons/EditOutlined";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import TimeAgo from "react-timeago";

import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  IconButton,
} from "@material-ui/core";

// import { getInitials } from 'helpers';

const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    padding: 0,
  },
  inner: {
    minWidth: 1050,
  },
  nameContainer: {
    display: "flex",
    alignItems: "center",
  },
  // avatar: {
  //   marginRight: theme.spacing(2)
  // },
  actions: {
    justifyContent: "flex-end",
  },
}));

const RestaurantsTable = (props) => {
  const {
    className,
    restaurants,
    restaurantsCount,
    changePageCallback,
    changePageSizeCallback,
    page,
    rowsPerPage,
    onFollowupClickedCallback,
    onDeleteCallback,
    ...rest
  } = props;

  const classes = useStyles();

  const handlePageChange = (event, page) => {
    changePageCallback(page);
  };

  const handleRowsPerPageChange = (event) => {
    changePageSizeCallback(event.target.value);
  };

  const onDelete = (id) => {
    onDeleteCallback(id);
  };

  // const onFollowupClicked = (propertyName, restaurantId, checked) => {
  //   onFollowupClickedCallback(propertyName, restaurantId, checked);
  // };

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <IconButton
                      size="small"
                      aria-label="add"
                      href="/admin/createrestaurant/"
                    >
                      <AddCircleOutlineOutlinedIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Address</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Telephone</TableCell>
                  <TableCell>Created</TableCell>
                  {/* <TableCell>Called</TableCell>
                  <TableCell>Interested</TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {restaurants.slice(0, rowsPerPage).map((restaurant) => (
                  <TableRow
                    className={classes.tableRow}
                    hover
                    key={restaurant.id}
                  >
                    <TableCell
                      style={{
                        whiteSpace: "nowrap",
                      }}
                    >
                      <IconButton
                        size="small"
                        aria-label="edit"
                        href={`/admin/restaurants/${restaurant.id}`}
                      >
                        <EditOutlined />
                      </IconButton>
                      <IconButton
                        size="small"
                        aria-label="delete"
                        onClick={(e) => onDelete(restaurant.id)}
                      >
                        <DeleteOutline />
                      </IconButton>
                      {/* <IconButton size="small" aria-label="delete"><DeleteOutline /></IconButton> */}
                    </TableCell>
                    <TableCell>{restaurant.name}</TableCell>
                    <TableCell>{restaurant.fullAddress}</TableCell>
                    <TableCell>{restaurant.email}</TableCell>
                    <TableCell>{restaurant.telephone}</TableCell>
                    <TableCell>
                      <TimeAgo date={restaurant.createdOn} />
                    </TableCell>
                    {/* <TableCell padding="checkbox">
                      <Checkbox checked={restaurant.called} onChange={e => onFollowupClicked('Called', restaurant.id, e.target.checked)} />
                    </TableCell>
                    <TableCell padding="checkbox">
                      <Checkbox checked={restaurant.interested} onChange={e => onFollowupClicked('Interested', restaurant.id, e.target.checked)} />
                    </TableCell> */}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={restaurantsCount}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </CardActions>
    </Card>
  );
};

RestaurantsTable.propTypes = {
  className: PropTypes.string,
  restaurants: PropTypes.array.isRequired,
};

export default RestaurantsTable;
