import React, { useState, useEffect } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { useAuth } from "./../../context/Auth";
import { Navigate } from "react-router";

import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField,
  Checkbox,
  FormGroup,
  FormControlLabel,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

const useStyles = makeStyles(() => ({
  root: {},
}));

var slugify = require("slugify");

const CreateRestaurant = (props) => {
  const { authToken } = useAuth();
  const { className, staticContext, ...rest } = props;
  const [allUsers, setAllUsers] = useState([]);
  const classes = useStyles();
  const [isError, setIsError] = useState(false);
  const [nowRedirect, setNowRedirect] = useState(false);
  const [userValue, setUserValue] = useState([]);
  const [values, setValues] = useState({
    name: "",
    userId: "6021be003765b900b40ccd55",
    fullAddress: "",
    address: "",
    instagram: "",
    city: "",
    country: "UK",
    postalCode: "",
    telephone: "",
    longitude: "",
    latitude: "",
    magicPaste: true,
  });

  const handleChange = (event) => {
    if (event.target.name === "magicPaste") {
      setAValue(event.target.name, event.target.checked);
      return;
    }

    if (!values.magicPaste) setAValue(event.target.name, event.target.value);
    else if (event.target.name === "postalCode") {
      var enteredPostalcode = event.target.value;
      var secondLastIndex = enteredPostalcode.lastIndexOf(
        " ",
        enteredPostalcode.lastIndexOf(" ") - 1
      );
      setAValue(
        event.target.name,
        enteredPostalcode.substr(secondLastIndex + 1)
      );
    } else if (event.target.name === "city") {
      var enteredCity = event.target.value;
      var secondLastIndex = enteredCity.lastIndexOf(
        " ",
        enteredCity.lastIndexOf(" ") - 1
      );
      // var thirdLastIndex = enteredCity.lastIndexOf(" ", secondLastIndex - 1);
      var lastCommaIndex = enteredCity.lastIndexOf(",");
      setAValue(
        event.target.name,
        enteredCity.substr(
          lastCommaIndex + 2,
          secondLastIndex - lastCommaIndex - 2
        )
      );
    } else if (event.target.name === "address") {
      var enteredAddress = event.target.value;
      var lastCommaIndex = enteredAddress.lastIndexOf(",");
      setAValue(event.target.name, enteredAddress.substr(0, lastCommaIndex));
    } else if (event.target.name === "longitude") {
      var enteredLongitude = event.target.value;
      var lastCommaIndex = enteredLongitude.lastIndexOf(",");
      setAValue(event.target.name, enteredLongitude.substr(lastCommaIndex + 2));
    } else if (event.target.name === "latitude") {
      var enteredLatitude = event.target.value;
      var lastCommaIndex = enteredLatitude.lastIndexOf(",");
      setAValue(event.target.name, enteredLatitude.substr(0, lastCommaIndex));
    } else setAValue(event.target.name, event.target.value);
  };

  const setAValue = (propertyName, propertyValue) => {
    setValues({
      ...values,
      [propertyName]: propertyValue,
    });
  };

  const onSubmitCallback = (event) => {
    event.preventDefault();
    createRestaurant(values);
  };

  const createRestaurant = async (restaurant) => {
    var url = `${process.env.REACT_APP_API_URL}/admin/createRestaurant/`;
    const formData = new FormData();
    Object.keys(restaurant).forEach((key) => {
      formData.append(key, restaurant[key]);
      if (key === "name") {
        formData.append(
          "slug",
          slugify(restaurant[key], { lower: true, strict: true })
        );
      }
    });
    fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      body: formData,
    }).then((response) => {
      if (response.ok) {
        setNowRedirect(true);
      } else {
        setIsError(true);
      }
    });
  };

  const fetchAllUsers = async () => {
    var url = `${process.env.REACT_APP_API_URL}/admin/GetAllUsers`;
    fetch(url, {
      method: "GET",
      headers: { Authorization: `Bearer ${authToken}` },
    })
      .then((response) => response.json())
      .then((data) => {
        setAllUsers(data);
        setUserValue({ id: "6021be003765b900b40ccd55", username: "yahya" });
      });
  };

  useEffect(() => {
    fetchAllUsers();
    return () => {
      setAllUsers([]);
    };
  }, []);

  if (nowRedirect) {
    return <Navigate to="/admin/restaurants" />;
  } else {
    return (
      <Card {...rest} className={clsx(classes.root, className)}>
        <form autoComplete="off" onSubmit={onSubmitCallback}>
          <CardHeader title="Create Restaurant" />
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Name"
                  margin="dense"
                  name="name"
                  onChange={handleChange}
                  required
                  value={values.name}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                {/* <TextField
                  fullWidth
                  label="User"
                  margin="dense"
                  name="userId"
                  onChange={handleChange}
                  required
                  value={values.userId}
                  variant="outlined"
                /> */}
                <Autocomplete
                  id="user-selection"
                  options={allUsers}
                  getOptionLabel={(option) =>
                    `${option.id} - ${option.username}`
                  }
                  required
                  fullWidth
                  value={userValue}
                  onChange={(event, newValue) => {
                    if (newValue === null) {
                      setAValue("userId", "");
                    } else {
                      setAValue("userId", newValue.id);
                    }
                    setUserValue(newValue);
                  }}
                  name="user"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="User"
                      variant="outlined"
                      margin="dense"
                    />
                  )}
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        defaultChecked
                        name="magicPaste"
                        onChange={handleChange}
                      />
                    }
                    label="Magic paste enabled"
                  />
                </FormGroup>
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Full Address"
                  margin="dense"
                  name="fullAddress"
                  onChange={handleChange}
                  required
                  value={values.fullAddress}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Address"
                  margin="dense"
                  name="address"
                  onChange={handleChange}
                  required
                  value={values.address}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="City"
                  margin="dense"
                  name="city"
                  onChange={handleChange}
                  required
                  value={values.city}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Country"
                  margin="dense"
                  name="country"
                  onChange={handleChange}
                  required
                  value={values.country}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Postal Code"
                  margin="dense"
                  name="postalCode"
                  onChange={handleChange}
                  required
                  value={values.postalCode}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Telephone"
                  margin="dense"
                  name="telephone"
                  onChange={handleChange}
                  required
                  value={values.telephone}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={3} xs={12}>
                <TextField
                  fullWidth
                  label="Longitude"
                  margin="dense"
                  name="longitude"
                  onChange={handleChange}
                  required
                  value={values.longitude}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={3} xs={12}>
                <TextField
                  fullWidth
                  label="Latitude"
                  margin="dense"
                  name="latitude"
                  onChange={handleChange}
                  required
                  value={values.latitude}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Instagram"
                  margin="dense"
                  name="instagram"
                  onChange={handleChange}
                  value={values.instagram}
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <CardActions>
            <Button color="primary" variant="contained" type="Submit">
              Create
            </Button>
          </CardActions>
        </form>
        {isError && <span>Something went wrong</span>}
      </Card>
    );
  }
};

CreateRestaurant.propTypes = {
  className: PropTypes.string,
};

export default CreateRestaurant;
