import React, { useState, useRef } from "react";
import { Routes, Route } from "react-router-dom";
// import { Route, Routes } from "react-router";
import ReactGA from "react-ga4";
import { createBrowserHistory } from "history";

import Layout from "./components/Layout";
import { Home } from "./components/Home";
import Restaurant from "./components/Restaurant";
import { AuthContext } from "./components/context/Auth";
// import PrivateRoute from "./components/PrivateRoute";
import Login from "./components/Login";
import jwt_decode from "jwt-decode";
import AdminLayout from "./components/admin/AdminLayout";
import Dashboard from "./components/admin/Dashboard";
import Restaurants from "./components/admin/Restaurants";
import Users from "./components/admin/Users";
import Posts from "./components/admin/Posts";
import { ThemeProvider } from "@material-ui/styles";
import theme from "./components/admin/components/theme";
import UserDetails from "./components/admin/components/UserDetails";
import RestaurantDetails from "./components/admin/components/RestaurantDetails";
import PostDetails from "./components/admin/components/PostDetails";
// import Followups from "./components/admin/Followups";
import VerifyEmail from "./components/VerifyEmail";
import ResetPassword from "./components/ResetPassword";

import "./custom.css";
import "./footer.css";
import RestaurantsInfo from "./components/RestaurantsInfo";
import About from "./components/About";
import CreatePost from "./components/admin/components/CreatePost";
import CreateRestaurant from "./components/admin/components/CreateRestaurant";
import Post from "./components/Post";

import withClearCache from "./ClearCache";
import CountryCity from "./components/CountryCity";
import Cuisine from "./components/Cuisine";
import Country from "./components/Country";
import Locations from "./components/Locations";
import ScrapedInstagramPosts from "./components/admin/ScrapedInstagramPosts";
import ScrapedInstagramPostDetails from "./components/admin/components/ScrapedInstagramPostDetails";
import OrderStickers from "./components/OrderStickers";

const ClearCacheComponent = withClearCache(MainApp);

const trackingId = "G-ST124DR2W0"; // Replace with your Google Analytics tracking ID
ReactGA.initialize(trackingId);
const history = createBrowserHistory();

history.listen((location) => {
  ReactGA.set({ page: location.pathname }); // Update the user's current page
  ReactGA.pageview(location.pathname); // Record a pageview for the given page
});

function App() {
  return <ClearCacheComponent />;
}

export default function MainApp(props) {
  const existingToken = JSON.parse(localStorage.getItem("token"));
  const [authToken, setAuthToken] = useState(existingToken);
  let _isAuthenticated = useRef(false);

  const setAuth = (data) => {
    if (data !== undefined) {
      localStorage.setItem("token", JSON.stringify(data));
      setAuthToken(data);
      _isAuthenticated.current = true;
    }
  };

  const resetAuth = () => {
    localStorage.removeItem("token");
    setAuthToken(null);
    _isAuthenticated.current = false;
  };

  const isLoggedIn = () => {
    return _isAuthenticated.current;
  };

  if (existingToken !== null) {
    const decodedToken = jwt_decode(existingToken, { complete: true });
    var current = (new Date().getTime() / 1000) | 0;
    if (decodedToken.exp > current) {
      _isAuthenticated.current = true;
    } else {
      resetAuth();
    }
  }
  const ProtectedRoute = ({ user, children }) => {
    if (!user) {
      return <Login />;
    }

    return children;
  };
  return (
    <AuthContext.Provider
      value={{
        authToken,
        setAuthCallback: setAuth,
        resetAuthCallback: resetAuth,
        isLoggedInFunc: isLoggedIn,
      }}
    >
      <Routes>
        <Route
          path="/admin/"
          element={
            <ProtectedRoute user={authToken}>
              <ThemeProvider theme={theme}>
                <AdminLayout>
                  <Dashboard />
                </AdminLayout>
              </ThemeProvider>
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/dashboard"
          element={
            <ProtectedRoute user={authToken}>
              <ThemeProvider theme={theme}>
                <AdminLayout>
                  <Dashboard />
                </AdminLayout>
              </ThemeProvider>
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/restaurants"
          element={
            <ProtectedRoute user={authToken}>
              <ThemeProvider theme={theme}>
                <AdminLayout>
                  <Restaurants />
                </AdminLayout>
              </ThemeProvider>
            </ProtectedRoute>
          }
        />

        <Route
          path="/admin/users"
          element={
            <ProtectedRoute user={authToken}>
              <ThemeProvider theme={theme}>
                <AdminLayout>
                  <Users />
                </AdminLayout>
              </ThemeProvider>
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/posts"
          element={
            <ProtectedRoute user={authToken}>
              <ThemeProvider theme={theme}>
                <AdminLayout>
                  <Posts />
                </AdminLayout>
              </ThemeProvider>
            </ProtectedRoute>
          }
        />

        <Route
          path="/admin/scraped"
          element={
            <ProtectedRoute user={authToken}>
              <ThemeProvider theme={theme}>
                <AdminLayout>
                  <ScrapedInstagramPosts />
                </AdminLayout>
              </ThemeProvider>
            </ProtectedRoute>
          }
        />

        <Route
          path="/admin/users/:id/:username"
          element={
            <ProtectedRoute user={authToken}>
              <ThemeProvider theme={theme}>
                <AdminLayout>
                  <UserDetails />
                </AdminLayout>
              </ThemeProvider>
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/restaurants/:id"
          element={
            <ProtectedRoute user={authToken}>
              <ThemeProvider theme={theme}>
                <AdminLayout>
                  <RestaurantDetails />
                </AdminLayout>
              </ThemeProvider>
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/scraped/:id"
          element={
            <ProtectedRoute user={authToken}>
              <ThemeProvider theme={theme}>
                <AdminLayout>
                  <ScrapedInstagramPostDetails />
                </AdminLayout>
              </ThemeProvider>
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/posts/:id"
          element={
            <ProtectedRoute user={authToken}>
              <ThemeProvider theme={theme}>
                <AdminLayout>
                  <PostDetails />
                </AdminLayout>
              </ThemeProvider>
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/createpost"
          element={
            <ProtectedRoute user={authToken}>
              <ThemeProvider theme={theme}>
                <AdminLayout>
                  <CreatePost />
                </AdminLayout>
              </ThemeProvider>
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/createrestaurant"
          element={
            <ProtectedRoute user={authToken}>
              <ThemeProvider theme={theme}>
                <AdminLayout>
                  <CreateRestaurant />
                </AdminLayout>
              </ThemeProvider>
            </ProtectedRoute>
          }
        />

        {/* <Route
          exact
          path="/"
          element={
            <Layout props={props}>
              <Home />
            </Layout>
          }
        />
        <Route
          exact
          path="/app"
          element={
            <Layout props={props}>
              <RestaurantsInfo />
            </Layout>
          }
        />
        <Route
          exact
          path="/about"
          element={
            <Layout props={props}>
              <About />
            </Layout>
          }
        />
        <Route
          exact
          path="/locations"
          element={
            <Layout props={props}>
              <Locations />
            </Layout>
          }
        />
        <Route
          exact
          path="/post/:postId/:name/"
          element={
            <Layout props={props}>
              <Post />
            </Layout>
          }
        />
        <Route
          exact
          path="/restaurant/:id/:name/"
          element={
            <Layout props={props}>
              <Restaurant />
            </Layout>
          }
        />
        <Route
          exact
          path="/country/:country"
          element={
            <Layout props={props}>
              <Country />
            </Layout>
          }
        />
        <Route
          exact
          path="/country/:country/city/:city"
          element={
            <Layout props={props}>
              <CountryCity />
            </Layout>
          }
        />
        <Route
          exact
          path="/cuisine/:cuisine"
          element={
            <Layout props={props}>
              <Cuisine />
            </Layout>
          }
        /> */}
        <Route
          exact
          path="/login"
          element={
            <Layout props={props}>
              <Login />
            </Layout>
          }
        />
        {/* <Route
          exact
          path="/email/verify/:t"
          element={
            <Layout props={props}>
              <VerifyEmail />
            </Layout>
          }
        />
        <Route
          exact
          path="/email/reset/:t"
          element={
            <Layout props={props}>
              <ResetPassword />
            </Layout>
          }
        />
        <Route
          exact
          path="/stickers"
          element={
            <Layout props={props}>
              <OrderStickers />
            </Layout>
          }
        />
        <Route
          exact
          path="/stickers/:code"
          element={
            <Layout props={props}>
              <OrderStickers />
            </Layout>
          }
        /> */}
      </Routes>
    </AuthContext.Provider>
  );
}
