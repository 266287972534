import React, { useContext } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Card, CardContent, Grid, Typography, Avatar } from "@material-ui/core";
import RateReviewOutlined from "@material-ui/icons/RateReviewOutlined";
import StatsContextProvider from "../contexts/StatsContext";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
  },
  content: {
    alignItems: "center",
    display: "flex",
  },
  title: {
    fontWeight: 700,
  },
  avatar: {
    backgroundColor: theme.palette.success.main,
    height: 56,
    width: 56,
  },
  icon: {
    height: 32,
    width: 32,
  },
  difference: {
    marginTop: theme.spacing(2),
    display: "flex",
    alignItems: "center",
  },
  differenceIcon: {
    color: theme.palette.success.dark,
  },
  differenceValue: {
    color: theme.palette.success.dark,
    marginRight: theme.spacing(1),
  },
}));

const TotalScraped = (props) => {
  const { totalScraped, unscraped, scrapedToDo } = useContext(
    StatsContextProvider.context
  );

  const { className, ...rest } = props;

  const classes = useStyles();

  return (
    <>
      <Card {...rest} className={clsx(classes.root, className)}>
        <CardContent>
          <Grid container justify="space-between">
            <Grid item>
              <Typography
                className={classes.title}
                color="textSecondary"
                gutterBottom
                variant="body2"
              >
                TOTAL SCRAPED
              </Typography>
              <Typography variant="h3">{totalScraped}</Typography>
            </Grid>
            <Grid item>
              <Typography
                className={classes.title}
                color="textSecondary"
                gutterBottom
                variant="body2"
              >
                SCRAPED TO DO
              </Typography>
              <Typography variant="h3">{scrapedToDo}</Typography>
            </Grid>
            <Grid item>
              <Typography
                className={classes.title}
                color="textSecondary"
                gutterBottom
                variant="body2"
              >
                UNSCRAPED
              </Typography>
              <Typography variant="h3">{unscraped}</Typography>
            </Grid>
            <Grid item>
              <Avatar className={classes.avatar}>
                <RateReviewOutlined className={classes.icon} />
              </Avatar>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

TotalScraped.propTypes = {
  className: PropTypes.string,
};

export default TotalScraped;
