import React, { useState, useEffect } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { useAuth } from "./../../context/Auth";
import Map from "./../../Map.js";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import OpenInBrowserIcon from "@mui/icons-material/OpenInBrowser";
import { useParams } from "react-router";

import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField,
} from "@material-ui/core";

const useStyles = makeStyles(() => ({
  root: {},
}));

const RestaurantDetails = (props) => {
  let { id } = useParams();
  const { authToken } = useAuth();
  const { className, staticContext, ...rest } = props;
  const [restaurantId] = useState(id);
  const classes = useStyles();
  const [values, setValues] = useState({
    id: "",
    name: "",
    fullAddress: "",
    address: "",
    instagram: "",
    city: "",
    country: "",
    postalCode: "",
    telephone: "",
    location: { coordinates: [0, 0] },
    createdOn: "",
    slug: "",
    pathToImage: "",
    description: "",
    restaurantUrl: "",
  });

  const fetchRestaurant = async () => {
    var userUrl = `${process.env.REACT_APP_API_URL}/restaurant/${restaurantId}`;
    fetch(userUrl, {
      method: "GET",
      headers: { Authorization: `Bearer ${authToken}` },
    })
      .then((response) => response.json())
      .then((data) => {
        data.restaurantUrl = `https://www.apicfood.com/restaurant/${data.id}/${data.slug}`;
        setValues(data);
      });
  };

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
  };

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });

    var url = `${process.env.REACT_APP_API_URL}/admin/updateRestaurantDetail/${restaurantId}`;
    const formData = new FormData();

    formData.append(event.target.name, event.target.value);
    fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      body: formData,
    });
  };

  useEffect(() => {
    fetchRestaurant();
    return () => {
      setValues({});
    };
  }, []);

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <form autoComplete="off" noValidate>
        <CardHeader title="Restaurant" />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Restaurant id"
                margin="dense"
                name="restaurantId"
                onChange={handleChange}
                required
                disabled
                value={values.id}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Name"
                margin="dense"
                name="name"
                onChange={handleChange}
                required
                value={values.name}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Full Address"
                margin="dense"
                name="fullAddress"
                onChange={handleChange}
                required
                value={values.fullAddress}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Address"
                margin="dense"
                name="address"
                onChange={handleChange}
                required
                value={values.address}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="City"
                margin="dense"
                name="city"
                onChange={handleChange}
                required
                value={values.city}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Country"
                margin="dense"
                name="country"
                onChange={handleChange}
                required
                value={values.country}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Postal Code"
                margin="dense"
                name="postalCode"
                onChange={handleChange}
                required
                value={values.postalCode}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Telephone"
                margin="dense"
                name="telephone"
                onChange={handleChange}
                required
                value={values.telephone}
                variant="outlined"
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <TextField
                fullWidth
                label="Longitude"
                margin="dense"
                name="longitude"
                onChange={handleChange}
                required
                value={values.location.coordinates[0]}
                variant="outlined"
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <TextField
                fullWidth
                label="Latitude"
                margin="dense"
                name="latitude"
                onChange={handleChange}
                required
                value={values.location.coordinates[1]}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Instagram"
                margin="dense"
                name="instagram"
                onChange={handleChange}
                required
                value={values.instagram}
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        size="small"
                        aria-label="add"
                        onClick={() => {
                          handleCopy(values.instagram);
                        }}
                      >
                        <ContentCopyOutlinedIcon />
                      </IconButton>
                      <IconButton
                        size="small"
                        aria-label="browser"
                        onClick={() => {
                          window.open(
                            `https://www.instagram.com/${values.instagram}`
                          );
                        }}
                      >
                        <OpenInBrowserIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Created"
                margin="dense"
                name="created"
                // onChange={handleChange}
                // required
                disabled
                value={values.createdOn}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Slug"
                margin="dense"
                name="slug"
                onChange={handleChange}
                required
                value={values.slug}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Restaurant URL"
                margin="dense"
                name="restaurantUrl"
                value={values.restaurantUrl}
                variant="outlined"
                disabled
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        size="small"
                        aria-label="add"
                        onClick={() => {
                          handleCopy(values.restaurantUrl);
                        }}
                      >
                        <ContentCopyOutlinedIcon />
                      </IconButton>
                      <IconButton
                        size="small"
                        aria-label="browser"
                        onClick={() => {
                          window.open(`${values.restaurantUrl}`);
                        }}
                      >
                        <OpenInBrowserIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <TextField
                fullWidth
                label="Description"
                margin="dense"
                name="description"
                onChange={handleChange}
                value={values.description}
                variant="outlined"
                multiline
                rows={3}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <Map
                center={{
                  lng: values.location.coordinates[0],
                  lat: values.location.coordinates[1],
                }}
                text={values.name}
              />
            </Grid>
          </Grid>
        </CardContent>
        {/* <Divider /> */}
        <CardActions>
          <Button color="primary" variant="contained" hidden>
            Save details
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};

RestaurantDetails.propTypes = {
  className: PropTypes.string,
};

export default RestaurantDetails;
