import React, { Component } from "react";
import Posts from "./Posts";
import { Helmet } from "react-helmet";

export class Home extends Component {
  static displayName = Home.name;

  render() {
    return (
      <div>
        <Helmet
          meta={[
            {
              name: "description",
              content: "find food near you. download the app today!",
            },
            {
              name: "keywords",
              content:
                "food, food photos, food near me, restaurants near me, discover food, see what to eat",
            },
          ]}
        >
          <title>apicfood - discover food, see what to eat</title>
          <link rel="canonical" href="https://www.apicfood.com/" />
          <link
            rel="apple-touch-icon"
            href="https://www.apicfood.com/logo.png"
          />
          <meta property="og:site_name" content="apicfood" />
          <meta property="og:url" content="https://www.apicfood.com/" />
          <meta property="og:type" content="website" />
          <meta
            property="og:title"
            content="apicfood - discover food, see what to eat"
          />
          <meta
            property="og:description"
            content="find food near you. download the app today!"
          />
          <meta
            property="og:image"
            content="https://cdn.apicfood.com/public/og.webp"
          />
          <meta property="twitter:card" content="summary_large_image" />
          <meta property="twitter:domain" content="apicfood.com" />
          <meta property="twitter:url" content="https://www.apicfood.com/" />
          <meta
            property="twitter:title"
            content="apicfood - discover food, see what to eat"
          />
          <meta
            property="twitter:description"
            content="find food near you. download the app today!"
          />
          <meta property="twitter:site" content="@apicfood" />
          <meta
            property="twitter:image"
            content="https://cdn.apicfood.com/public/og.webp"
          />
        </Helmet>
        <Posts />
      </div>
    );
  }
}
