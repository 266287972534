import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { Button } from "@material-ui/core";

import SearchInput from "./SearchInput";
// import SearchContextProvider from '../contexts/SearchContext';
import { useSearchContext } from "./../contexts/SearchContext";

const useStyles = makeStyles((theme) => ({
  root: {},
  row: {
    height: "42px",
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(1),
  },
  spacer: {
    flexGrow: 1,
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
  searchInput: {
    marginRight: theme.spacing(1),
  },
}));

const GridSearch = (props) => {
  const { onSearchCallback, placeholder } = useSearchContext();
  const { className, ...rest } = props;

  const classes = useStyles();

  const onSearchClicked = (event) => {
    event.preventDefault();
    onSearchCallback();
  };

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <div className={classes.row + " ml-1"}>
        <form className="form-inline" onSubmit={onSearchClicked}>
          <div className="form-row">
            <div className="form-group">
              <SearchInput
                className={classes.searchInput}
                placeholder={placeholder}
              />
            </div>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              className="mr-1"
            >
              Search
            </Button>
            {/* <Button color="primary" variant="contained" className="mr-1">Add New</Button> */}
          </div>
        </form>
      </div>
    </div>
  );
};

GridSearch.propTypes = {
  className: PropTypes.string,
};

export default GridSearch;
