import React, { useState, useEffect, useRef } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { useAuth } from "../../context/Auth";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import OpenInBrowserIcon from "@mui/icons-material/OpenInBrowser";
import Autocomplete from "@mui/material/Autocomplete";
import { useParams } from "react-router";

import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField,
  Chip,
} from "@material-ui/core";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles(() => ({
  root: {},
}));

const cuisines = [
  "Afghan",
  "American",
  "Argentine",
  "Asian",
  "Austrian",
  "Bagels",
  "Bakery",
  "Barbecue",
  "Belgian",
  "Bistro",
  "Brazilian",
  "British",
  "Buffet",
  "Burgers",
  "Cafe",
  "Cajun",
  "Californian",
  "Calzones",
  "Cambodian",
  "Caribbean",
  "Catering",
  "Cheesesteaks",
  "Chicken",
  "Chinese",
  "Chowder",
  "Cocktails",
  "Coffee",
  "Colombian",
  "Contemporary",
  "Continental",
  "Creole",
  "Crepes",
  "Cuban",
  "Czech",
  "Deli",
  "Dim Sum",
  "Diner",
  "Dominican",
  "Donuts",
  "Eastern European",
  "Eclectic",
  "English",
  "Ethiopian",
  "European",
  "Fast Food",
  "Filipino",
  "Fish and Chips",
  "Fondue",
  "French",
  "Frozen Yogurt",
  "Fusion",
  "Gastropub",
  "German",
  "Greek",
  "Grill",
  "Gyros",
  "Haitian",
  "Halal",
  "Hawaiian",
  "Healthy",
  "Hookah Bar",
  "Hot Dogs",
  "Ice Cream",
  "Indian",
  "Indonesian",
  "International",
  "Irish",
  "Israeli",
  "Italian",
  "Japanese",
  "Juices",
  "Korean",
  "Korean Barbeque",
  "Kosher",
  "Latin",
  "Latin American",
  "Lebanese",
  "Malaysian",
  "Mediterranean",
  "Mexican",
  "Middle Eastern",
  "Mongolian",
  "Moroccan",
  "Nepalese",
  "Noodle Bar",
  "Norwegian",
  "Organic",
  "Oysters",
  "Pacific Rim",
  "Pakistani",
  "Pan Asian",
  "Pasta",
  "Pasteries",
  "Persian",
  "Peruvian",
  "Pho",
  "Pizza",
  "Polish",
  "Polynesian",
  "Portuguese",
  "Pub Food",
  "Puerto Rican",
  "Ribs",
  "Salad",
  "Salvadoran",
  "Sandwiches",
  "Seafood",
  "Singaporean",
  "Smoothies",
  "Soul Food",
  "Soup",
  "South American",
  "South Pacific",
  "Southern",
  "Southwestern",
  "Spanish",
  "Steak",
  "Subs",
  "Sushi",
  "Taiwanese",
  "Tapas",
  "Tea",
  "Tex Mex",
  "Thai",
  "Tibetan",
  "Traditional",
  "Turkish",
  "Ukrainian",
  "Vegan",
  "Vegetarian",
  "Venezuelan",
  "Venusian",
  "Vietnamese",
  "Wings",
  "Wraps",
];

var slugify = require("slugify");

const ScrapedInstagramPostDetails = (props) => {
  let { id } = useParams();
  const { authToken } = useAuth();
  const [restaurantsUsers, setRestaurantsUsers] = useState([]);
  const { className, staticContext, ...rest } = props;
  const [instagramPostId] = useState(id);
  const classes = useStyles();
  const [defaultRestaurants, setDefaultRestaurants] = useState([]);
  const [filterInputValue, setFilterInputValue] = useState("");
  const createPostAndLoadNextButton = useRef();
  const [values, setValues] = useState({
    _id: "",
    instagram: "",
    restaurantName: "",
    display_url: "",
    markedRead: false,
    text: "",
    taken_at_timestamp: "",
    hashtags: [],
    base64Image: "",
    cuisines: "",
    restaurantsId:
      defaultRestaurants.length > 0
        ? defaultRestaurants.map((x) => {
            return x.restaurantId;
          })
        : [],
    restaurantAutocompleteValues:
      defaultRestaurants.length > 0 ? defaultRestaurants : [],
    name: "",
    cuisine: "",
    userId: defaultRestaurants.length > 0 ? defaultRestaurants[0].userId : "",
    postImageDownloadFrom: "",
    description: "",
  });

  const fetchInstagramPost = async () => {
    var url = `${process.env.REACT_APP_API_URL}/scraped/getpost/${instagramPostId}`;
    fetch(url, {
      method: "GET",
      headers: { Authorization: `Bearer ${authToken}` },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        data.taken_at_timestamp = new Date(data.taken_at_timestamp * 1000)
          .toISOString()
          .replace("Z", "");

        const newData = { ...values, ...data };
        return newData;
      })
      .then(async (newData) => {
        var url = `api/admin/GetRestaurantsUsersByInstagram/${newData.instagram}`;

        const response = await fetch(url, {
          method: "GET",
          headers: { Authorization: `Bearer ${authToken}` },
        }).then((r) => {
          return r.json();
        });
        return {
          newData: newData,
          fetchResponse: response,
        };
      })
      .then((resp) => {
        const restaurantUsers = resp.fetchResponse;
        resp.newData.restaurantsId = restaurantUsers.map((x) => {
          return x.restaurantId;
        });
        resp.newData.restaurantAutocompleteValues = restaurantUsers;
        resp.newData.userId =
          restaurantUsers.length > 0 ? restaurantUsers[0].userId : "";
        // console.log(restaurantUsers[0]);
        resp.newData.cuisine =
          restaurantUsers.length > 0
            ? restaurantUsers[0].lastCuisine !== undefined
              ? restaurantUsers[0].lastCuisine
              : ""
            : "";
        setValues(resp.newData);
        // setRestaurantsUsers(response.data);
        // setDefaultRestaurants(data);
      })
      .then(() => {
        if (createPostAndLoadNextButton.current) {
          createPostAndLoadNextButton.current.focus();
        }
      });
  };

  // const fetchRestaurantsUsers = async (instagram) => {};

  const selectFiltered = () => {
    if (filterInputValue === "") {
      setThreeValues(
        "userId",
        "",
        "restaurantsId",
        [],
        "restaurantAutocompleteValues",
        []
      );
      return;
    }
    const filterValues = restaurantsUsers.filter((restaurantsUser) =>
      restaurantsUser.restaurantName
        .toLowerCase()
        .includes(filterInputValue.toLowerCase())
    );
    if (filterValues.length > 0) {
      setThreeValues(
        "userId",
        filterValues[0].userId,
        "restaurantsId",
        filterValues.map((x) => {
          return x.restaurantId;
        }),
        "restaurantAutocompleteValues",
        filterValues
      );
    } else {
      setThreeValues(
        "userId",
        "",
        "restaurantsId",
        [],
        "restaurantAutocompleteValues",
        []
      );
    }
  };

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const setAValue = (propertyName, propertyValue) => {
    setValues({
      ...values,
      [propertyName]: propertyValue,
    });
  };

  const setThreeValues = (
    property1Name,
    property1Value,
    property2Name,
    property2Value,
    property3Name,
    property3Value
  ) => {
    setValues({
      ...values,
      [property1Name]: property1Value,
      [property2Name]: property2Value,
      [property3Name]: property3Value,
    });
  };

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
  };

  const markCompleted = () => {
    var url = `${process.env.REACT_APP_API_URL}/scraped/markCompleted/${instagramPostId}`;
    fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    }).then((response) => {
      if (response.ok) window.location.href = "/admin/scraped";
      else return false;
    });
  };

  const createPost = async () => {
    if (values.name === "") return;
    var post = {
      name: values.name,
      cuisine: values.cuisine,
      restaurantsId: values.restaurantsId,
      userId: values.userId,
      postImageDownloadFrom: values.display_url,
      hashtags: values.hashtags,
      slug: slugify(values.name, { lower: true, strict: true }),
      description: values.description,
    };
    var url = `${process.env.REACT_APP_API_URL}/admin/createpost`;
    const formData = new FormData();
    Object.keys(post).forEach((key) => {
      if (key !== "restaurantAutocompleteValues") {
        formData.append(key, post[key]);
      }
    });

    fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      body: formData,
    }).then((response) => {
      if (response.ok) {
      } else {
        throw new Error("Post creation failed");
      }
    });
  };

  const markCompletedAndLoadNext = () => {
    var url = `${process.env.REACT_APP_API_URL}/scraped/markCompletedLoadNext/${instagramPostId}`;
    fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then((response) => {
        if (response.ok) return response.json();
      })
      .then((data) => {
        window.location.href = `/admin/scraped/${data._id}`;
      });
  };

  const createPostAndLoadNext = () => {
    var url = `${process.env.REACT_APP_API_URL}/scraped/markCompletedLoadNext/${instagramPostId}`;
    createPost()
      .then(() => {
        return fetch(url, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });
      })
      .then((response) => {
        if (response.ok) return response.json();
      })
      .then((data) => {
        window.location.href = `/admin/scraped/${data._id}`;
      });
  };

  useEffect(() => {
    fetchInstagramPost();
    return () => {
      setValues({});
    };
  }, []);

  // useEffect(() => {
  //   fetchRestaurantsUsers();
  //   return () => {
  //     setRestaurantsUsers([]);
  //   };
  // }, []);

  return (
    // <Card {...rest} className={clsx(classes.root, className)}>
    <form autoComplete="off" noValidate>
      <CardHeader title="Instagram Post" />
      <Divider />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item md={6} xs={12}>
            <TextField
              fullWidth
              label="Instagram Post Id"
              margin="dense"
              name="instagramPostId"
              onChange={handleChange}
              required
              disabled
              value={values._id}
              variant="outlined"
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              fullWidth
              label="Posted at"
              type="datetime-local"
              margin="dense"
              name="At"
              onChange={handleChange}
              required
              disabled
              value={values.taken_at_timestamp}
              variant="outlined"
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <TextField
              fullWidth
              label="Text"
              margin="dense"
              name="text"
              onChange={handleChange}
              required
              disabled
              value={values.text}
              variant="outlined"
              multiline
              rows={10}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              fullWidth
              label="Instagram"
              margin="dense"
              name="instagram"
              onChange={handleChange}
              required
              disabled
              value={values.instagram}
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      size="small"
                      aria-label="add"
                      onClick={() => {
                        handleCopy(values.instagram);
                      }}
                    >
                      <ContentCopyOutlinedIcon />
                    </IconButton>
                    <IconButton
                      size="small"
                      aria-label="browser"
                      onClick={() => {
                        window.open(
                          `https://www.instagram.com/${values.instagram}`
                        );
                      }}
                    >
                      <OpenInBrowserIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              fullWidth
              label="Restaurant Name"
              margin="dense"
              name="restaurantName"
              onChange={handleChange}
              required
              disabled
              value={values.restaurantName}
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      size="small"
                      aria-label="add"
                      onClick={() => {
                        handleCopy(values.restaurantName);
                      }}
                    >
                      <ContentCopyOutlinedIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <TextField
              fullWidth
              label="Name"
              margin="dense"
              name="name"
              onChange={handleChange}
              required
              value={values.name}
              variant="outlined"
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <Autocomplete
              id="combo-box-demo"
              options={cuisines}
              // getOptionLabel={(option) => option}
              required
              fullWidth
              value={values.cuisine}
              onChange={(event, newValue) => {
                setAValue("cuisine", newValue);
              }}
              name="cuisine"
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Cuisine"
                  variant="outlined"
                  margin="dense"
                  name="cuisine"
                  value={values.cuisine}
                  // onChange={handleChange}
                  required
                />
              )}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <Button
              ref={createPostAndLoadNextButton}
              color="primary"
              variant="contained"
              onClick={() => {
                createPostAndLoadNext();
              }}
            >
              Create Post & Load Next
            </Button>
          </Grid>
          <Grid item md={12} xs={12}>
            <TextField
              fullWidth
              label="Description"
              margin="dense"
              name="description"
              onChange={handleChange}
              value={values.description}
              variant="outlined"
              multiline
              rows={3}
            />
          </Grid>
          <Grid item md={11} xs={11}>
            <Autocomplete
              id="restaurant-users-selection"
              value={values.restaurantAutocompleteValues}
              options={restaurantsUsers}
              getOptionLabel={(option) =>
                `${option.restaurantId} - ${option.restaurantName}`
              }
              isOptionEqualToValue={(option, value) => {
                return option.restaurantId === value.restaurantId;
              }}
              defaultValue={defaultRestaurants}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {`${option.restaurantName} - ${option.restaurantAddress} - ${option.username}`}
                </li>
              )}
              required
              fullWidth
              multiple
              freeSolo
              // filterSelectedOptions
              onInputChange={(event, value) => {
                setFilterInputValue(value);
              }}
              onChange={(event, newValue, reason) => {
                if (newValue.length > 0) {
                  setThreeValues(
                    "userId",
                    newValue[0].userId,
                    "restaurantsId",
                    newValue.map((x) => {
                      return x.restaurantId;
                    }),
                    "restaurantAutocompleteValues",
                    newValue
                  );
                } else {
                  setThreeValues(
                    "userId",
                    "",
                    "restaurantsId",
                    [],
                    "restaurantAutocompleteValues",
                    []
                  );
                }
              }}
              name="restaurant"
              renderInput={(params) => (
                <>
                  <TextField
                    {...params}
                    label="Restaurant"
                    variant="outlined"
                    margin="dense"
                  />
                </>
              )}
            />
          </Grid>
          <Grid item md={1} xs={1}>
            <Button
              // margin="dense"
              // color="primary"
              // variant="contained"
              // type="Submit"
              onClick={() => {
                selectFiltered();
              }}
            >
              Filtered
            </Button>
          </Grid>
          <Grid item md={6} xs={12}>
            <img
              style={{ height: 300 }}
              className="img-responsive"
              src={"data:image/png;base64," + values.base64Image}
              alt={values.name}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            {/* <Link href={values.display_url} rel="noreferrer" target="_blank">
                View Image
              </Link> */}
            <IconButton
              size="small"
              aria-label="add"
              onClick={() => {
                handleCopy(values.display_url);
              }}
            >
              <ContentCopyOutlinedIcon />
            </IconButton>
          </Grid>
          <Grid item md={6} xs={12}>
            <FormControl
              component="fieldset"
              className={classes.formControl}
              disabled
            >
              <FormGroup>
                <div className={classes.root}>
                  {values.hashtags.length > 0 &&
                    values.hashtags.map(function (tag, index) {
                      return (
                        <Chip
                          key={index}
                          value={tag}
                          variant="outlined"
                          color="secondary"
                          size="small"
                          className="mr-1"
                          label={tag}
                        />
                      );
                    })}
                </div>
              </FormGroup>
            </FormControl>
          </Grid>
          <Grid item md={6} xs={12}>
            <IconButton
              size="small"
              aria-label="add"
              onClick={() => {
                if (values.hashtags.length > 0)
                  handleCopy(values.hashtags.join(" "));
              }}
            >
              <ContentCopyOutlinedIcon />
            </IconButton>
          </Grid>
        </Grid>
      </CardContent>
      {/* <Divider /> */}
      <CardActions>
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            markCompletedAndLoadNext();
          }}
        >
          Mark Completed & Load Next
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            createPostAndLoadNext();
          }}
        >
          Create Post & Load Next
        </Button>
      </CardActions>
    </form>
    // </Card>
  );
};

export default ScrapedInstagramPostDetails;
