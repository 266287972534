import React, { useState, useEffect, useRef } from "react";
import TimelinePost from "./TimelinePost";
import InfiniteScroll from "react-infinite-scroll-component";
import { faLocationArrow } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useQueryState } from "react-router-use-location-state";
import PlacesAutocomplete from "../components/PlacesAutocomplete";
import NoMore from "./NoMore";
import ReactGA from "react-ga4";

export default function Posts() {
  const [posts, setPosts] = useState([]);
  const placeCoordinates = useRef(undefined);
  const [, setLat] = useQueryState("lat", "");
  const [, setLng] = useQueryState("lng", "");
  const selectedLocation = useRef("");
  const [locationQuery, setLocationQuery] = useQueryState("location", "");
  const locationCriteria = useRef(locationQuery);
  const [searchDisabled, setSearchDisabled] = useState(false);
  let _lastPostDateTime = useRef(undefined);

  const [searchQuery, setSearchQuery] = useQueryState("search", "");
  const [searchCriteria, setSearchCriteria] = useState(searchQuery);

  const hasMore = useRef(true);
  // const [, setShowEmpty] = useState(false);

  const setCoordinates = (latLng) => {
    placeCoordinates.current = latLng;

    if (latLng === undefined) {
      setLat("");
      setLng("");
    } else {
      setLat(Number.parseFloat(latLng.lat).toFixed(2));
      setLng(Number.parseFloat(latLng.lng).toFixed(2));
    }
  };

  const setLocation = (location) => {
    selectedLocation.current = location;
    setLocationQuery(location);
  };

  const getCurrentLocation = (event) => {
    event.preventDefault();
    setSearchDisabled(true);
    locationCriteria.current = "Current Location";
    selectedLocation.current = "Current Location";
    navigator.geolocation.getCurrentPosition(function (position) {
      setSearchDisabled(false);
      placeCoordinates.current = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      };
      submitSearchForm(event);
    });
  };

  const fetchPosts = async () => {
    let url = new URL(window.location.href);
    let params = new URLSearchParams(url.search);

    var postUrl = `${process.env.REACT_APP_API_URL}/posts?${params.toString()}`;

    fetch(postUrl)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        hasMore.current = data.length > 0;
        if (data.length > 0) {
          _lastPostDateTime.current = new Date(
            data[data.length - 1].postedAt
          ).getTime();
        } else {
          _lastPostDateTime = undefined;
        }
        // setShowEmpty(data.length === 0);
        setPosts(data);
      });
  };

  const loadMore = async () => {
    let url = new URL(window.location.href);
    let params = new URLSearchParams(url.search);
    if (_lastPostDateTime.current !== undefined)
      params.set("lastPostDateTime", _lastPostDateTime.current);
    if (posts.length > 1) params.set("skip", posts.length);

    var postUrl = `${process.env.REACT_APP_API_URL}/posts?${params.toString()}`;

    fetch(postUrl)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        hasMore.current = data.length > 0;
        var newPosts = posts.concat(data);
        setPosts(newPosts);
        if (newPosts.length > 0)
          _lastPostDateTime.current = new Date(
            newPosts[newPosts.length - 1].postedAt
          ).getTime();
        else _lastPostDateTime = undefined;
        // setShowEmpty(newPosts.length === 0);
      });
  };

  const submitSearchForm = (event) => {
    event.preventDefault();
    _lastPostDateTime.current = undefined;
    setSearchQuery(searchCriteria);
    if (placeCoordinates.current !== undefined) {
      setLat(Number.parseFloat(placeCoordinates.current.lat).toFixed(2));
      setLng(Number.parseFloat(placeCoordinates.current.lng).toFixed(2));
    }
    if (selectedLocation.current !== "") {
      setLocationQuery(selectedLocation.current);
    }
    fetchPosts();
    // setFetchNow(!fetchNow);
  };

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
    fetchPosts();
  }, []);

  return (
    <div>
      <div className="container">
        <div className="">
          <h1 className="h1 font-weight-bold text-uppercase apicFont">
            Find food - see what to eat
          </h1>
        </div>
        <div className="">
          <form onSubmit={submitSearchForm}>
            <div className="form-row">
              <div className="col-lg-5 col-md-6 col-sm-12 col-xs-12 mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Burger, Thai, Fish and Chips..."
                  value={searchCriteria}
                  onChange={(e) => {
                    setSearchCriteria(e.target.value);
                  }}
                />
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 mb-3">
                <PlacesAutocomplete
                  setCoordinates={setCoordinates}
                  setLocation={setLocation}
                  initLocation={locationCriteria.current}
                />
              </div>
              <div className="col-lg-1 col-md-6 col-sm-6 col-xs-6 mb-3">
                <button
                  type="button"
                  className="btn btn-primary btn-block"
                  onClick={getCurrentLocation}
                >
                  <FontAwesomeIcon
                    icon={faLocationArrow}
                    style={{ color: "#fff" }}
                  />
                </button>
              </div>
              <div className="col-lg-2 col-md-6 col-sm-6 col-xs-6 mb-3">
                <button
                  type="submit"
                  className="btn btn-primary btn-block code"
                  disabled={searchDisabled}
                >
                  Search
                </button>
              </div>
            </div>
          </form>
        </div>
        <div className="infiniteScrollContainer" hidden={posts.length === 0}>
          <InfiniteScroll
            dataLength={posts.length}
            hasMore={hasMore.current}
            next={loadMore}
            scrollThreshold="600px"
          >
            <div className="row">
              {posts.map((currentPost) => (
                <TimelinePost key={currentPost.id} post={currentPost} />
              ))}
            </div>
          </InfiniteScroll>
        </div>
        <NoMore hasMore={posts.length !== 0} />
      </div>
    </div>
  );
}
