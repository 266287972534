import React from "react";
import empty from "../images/emptyPlate.webp";

export default function NoMore({ hasMore }) {
  if (!hasMore)
    return (
      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-3">
        <div className="col-12">
          <div className="card">
            <div className="card-image">
              <img
                className="empty-card-img-top"
                src={empty}
                alt="Nothing more to show!"
              />
            </div>
            <div className="card-body">
              <h5 className="card-title apicFont">Nothing to show!</h5>
            </div>
          </div>
        </div>
      </div>
    );
  else return null;
}
