import React, { useState, createContext, useEffect, Children } from "react";
import { useAuth } from "./../../context/Auth";

const context = createContext(null);

export default function StatsContextProvider({ children }) {
  const { authToken } = useAuth();
  const [stats, setStats] = useState({});
  function fetchStats() {
    var url = `${process.env.REACT_APP_API_URL}/admin/GetStats`;
    fetch(url, {
      method: "GET",
      headers: { Authorization: `Bearer ${authToken}` },
    })
      .then((response) => response.json())
      .then((result) => {
        setStats(result);
      });
  }

  useEffect(() => {
    fetchStats();
    return () => {
      setStats({});
    };
  }, []);

  return <context.Provider value={{ ...stats }}>{children}</context.Provider>;
}

StatsContextProvider.context = context;
