import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Paper, Input } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { useSearchContext } from './../contexts/SearchContext';

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: '4px',
    alignItems: 'center',
    padding: theme.spacing(1),
    display: 'flex',
    flexBasis: 420
  },
  icon: {
    marginRight: theme.spacing(1),
    color: theme.palette.text.secondary
  },
  input: {
    flexGrow: 1,
    fontSize: '14px',
    lineHeight: '16px',
    letterSpacing: '-0.05px'
  }
}));

const SearchInput = props => {
  const { search, onSearchChangeCallback } = useSearchContext();
  const { className, style, ...rest } = props;
  const [searchCriteria, setSearchCriteria] = useState(search);

  const classes = useStyles();

  const onSearchChange = (searchValue) => {
    setSearchCriteria(searchValue);
    onSearchChangeCallback(searchValue);
  }

  return (
    <Paper {...rest} className={clsx(classes.root, className)} style={style}>
      <SearchIcon className={classes.icon} />
      <Input {...rest} className={classes.input} disableUnderline value={searchCriteria} onChange={e => onSearchChange(e.target.value)} />
    </Paper>
  );
};

SearchInput.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  style: PropTypes.object
};

export default SearchInput;
