import React from "react";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function TimelinePost({ post }) {
  return (
    <div key={post.id} className="col-lg-4 col-md-4 col-sm-12 col-xs-12 mb-3">
      <div className="card">
        <div className="card-image">
          <a href={`/post/${post.id}/${post.slug}`}>
            <img
              className="card-img-top"
              src={post.pathToImage.replace(/\.[^.]+$/, ".340.webp")}
              alt={post.name}
            />
          </a>
        </div>
        <div className="card-body">
          <div className="float-left">
            <h5 className="card-title">
              <a
                className="alternateFont"
                href={`/post/${post.id}/${post.slug}`}
              >
                {post.name}
              </a>
            </h5>
            <h6 className="card-title">
              <FontAwesomeIcon className="apicFont" icon={faMapMarkerAlt} />{" "}
              <a
                className="apicFont"
                href={`/restaurant/${post.restaurant.id}/${post.restaurant.slug}`}
              >
                {post.restaurant.name}
              </a>
            </h6>
          </div>
        </div>
      </div>
    </div>
  );
}
