import React from "react";
import GoogleMapReact from "google-map-react";
import Marker from "./Marker";

// const MarkerComponent = ({ text }) => <div>{text}</div>;

export default function App({ center, text }) {
  const centerAsArray = [];
  centerAsArray[0] = center.lat;
  centerAsArray[1] = center.lng;

  if (center.lat !== 0 && center.lng !== 0) {
    return (
      <div style={{ height: "50vh", width: "100%" }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyAf3-ELUBDrJNHo8sxjgkwfijqDfX6bv0c" }}
          center={centerAsArray}
          defaultZoom={13}
        >
          <Marker lat={center.lat} lng={center.lng} name={text} />
        </GoogleMapReact>
      </div>
    );
  }

  return <div></div>;
}
