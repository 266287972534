import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { useQueryState } from "react-router-use-location-state";
import UsersToolbar from "./UsersToolbar";
import UsersTable from "./UsersTable";
import { useAuth } from "./../../context/Auth";
import { SearchContext } from "./../contexts/SearchContext";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
}));

export default function UserList() {
  const { authToken } = useAuth();
  const classes = useStyles();
  const [users, setUsers] = useState([]);
  const [totalUsers, setTotalUsers] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [fetchNow, setFetchNow] = useState(true);

  const [searchQuery, setSearchQuery] = useQueryState("search", "");
  const [searchCriteria, setSearchCriteria] = useState(searchQuery);

  const changePage = (newPageNumber) => {
    setPageNumber(newPageNumber);
  };

  const changePageSize = (pageSize) => {
    setPageSize(pageSize);
  };

  const onSearch = () => {
    setSearchCriteria(searchCriteria);
    setSearchQuery(searchCriteria);
    setFetchNow(!fetchNow);
    setPageNumber(0);
  };

  const onSearchChange = (searchedCriteria) => {
    setSearchCriteria(searchedCriteria);
  };

  const fetchUsers = async () => {
    var queryString = window.location.search;
    var usersUrl = `${process.env.REACT_APP_API_URL}/admin/getusers${queryString}`;
    var queryPart = queryString === "" ? "?" : "&";
    usersUrl =
      usersUrl + queryPart + "page=" + pageNumber + "&size=" + pageSize;
    fetch(usersUrl, {
      method: "GET",
      headers: { Authorization: `Bearer ${authToken}` },
    })
      .then((response) => response.json())
      .then((data) => {
        setUsers(data.items);
        setTotalUsers(data.totalItems);
      });
  };

  useEffect(() => {
    fetchUsers();
    return () => {
      setUsers([]);
    };
  }, [fetchNow, pageSize, pageNumber]);

  return (
    <div className={classes.root}>
      <SearchContext.Provider
        value={{
          search: searchCriteria,
          onSearchChangeCallback: onSearchChange,
          onSearchCallback: onSearch,
        }}
      >
        <UsersToolbar />
      </SearchContext.Provider>
      <div className={classes.content}>
        <UsersTable
          users={users}
          usersCount={totalUsers}
          changePageCallback={changePage}
          changePageSizeCallback={changePageSize}
          page={pageNumber}
          rowsPerPage={pageSize}
        />
      </div>
    </div>
  );
}
