import React from "react";
import clsx from "clsx";
import PerfectScrollbar from "react-perfect-scrollbar";
import { makeStyles } from "@material-ui/styles";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import TimeAgo from "react-timeago";

import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  IconButton,
  Chip,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    padding: 0,
  },
  inner: {
    minWidth: 1050,
  },
  nameContainer: {
    display: "flex",
    alignItems: "center",
  },
  actions: {
    justifyContent: "flex-end",
  },
}));

const ScrapedInstagramPostsTable = (props) => {
  const {
    className,
    posts,
    postsCount,
    changePageCallback,
    changePageSizeCallback,
    page,
    rowsPerPage,
    onDeletePostCallback,
    ...rest
  } = props;

  const classes = useStyles();

  const handlePageChange = (event, page) => {
    changePageCallback(page);
  };

  const handleRowsPerPageChange = (event) => {
    changePageSizeCallback(event.target.value);
  };

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>Restaurant</TableCell>
                  <TableCell>Instagram</TableCell>
                  <TableCell>At</TableCell>
                  <TableCell>Hashtags</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {posts.slice(0, rowsPerPage).map((post) => (
                  <TableRow className={classes.tableRow} hover key={post._id}>
                    <TableCell
                      style={{
                        whiteSpace: "nowrap",
                      }}
                    >
                      <IconButton
                        size="small"
                        aria-label="edit"
                        href={`/admin/scraped/${post._id}`}
                      >
                        <EditOutlinedIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell>{post.restaurantName}</TableCell>
                    <TableCell>{post.instagram}</TableCell>
                    <TableCell>
                      <TimeAgo date={post.taken_at_timestamp * 1000} />
                    </TableCell>
                    <TableCell>
                      <div className={classes.root}>
                        {post.hashtags.map(function (name, index) {
                          return (
                            <Chip
                              key={index}
                              value={name}
                              variant="outlined"
                              color="secondary"
                              size="small"
                              className="mr-1"
                              label={name}
                            />
                          );
                        })}
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={postsCount}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </CardActions>
    </Card>
  );
};

export default ScrapedInstagramPostsTable;
