import React, { useState, useEffect } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { useAuth } from "./../../context/Auth";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { useParams } from "react-router";

import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField,
  Chip,
} from "@material-ui/core";

const useStyles = makeStyles(() => ({
  root: {},
}));

const UserDetails = (props) => {
  let { id } = useParams();
  const { authToken } = useAuth();
  const { className, staticContext, ...rest } = props;
  const [userId] = useState(id);
  const classes = useStyles();
  // const [roles, setRoles] = React.useState(() => []);
  const [values, setValues] = useState({
    id: "",
    username: "",
    email: "",
    roles: [],
  });

  const [roles, setRoles] = React.useState({
    Admin: false,
    Restaurateur: false,
  });

  const { Admin, Restaurateur } = roles;

  const handleRoles = (event) => {
    setRoles({ ...roles, [event.target.name]: event.target.checked });
  };

  const fetchUser = async () => {
    var userUrl = `${process.env.REACT_APP_API_URL}/users/${userId}`;
    fetch(userUrl, {
      method: "GET",
      headers: { Authorization: `Bearer ${authToken}` },
    })
      .then((response) => response.json())
      .then((data) => {
        setValues(data);
        var currentRoles = { ...roles };
        data.roles.forEach(
          (roleObj) => (currentRoles = { ...currentRoles, [roleObj]: true })
        );
        setRoles({ ...roles, ...currentRoles });
      });
  };

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  useEffect(() => {
    fetchUser();
    return () => {
      setValues({});
    };
  }, []);

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <form autoComplete="off" noValidate>
        <CardHeader title="User" />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="User id"
                margin="dense"
                name="userId"
                onChange={handleChange}
                required
                disabled
                value={values.id}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Username"
                margin="dense"
                name="username"
                onChange={handleChange}
                required
                disabled
                value={values.username}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Email Address"
                margin="dense"
                name="email"
                onChange={handleChange}
                required
                disabled
                value={values.email}
                variant="outlined"
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <FormControl
                component="fieldset"
                className={classes.formControl}
                disabled
              >
                <FormLabel component="legend">Roles</FormLabel>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={Admin}
                        onChange={handleRoles}
                        name="Admin"
                      />
                    }
                    label="Admin"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={Restaurateur}
                        onChange={handleRoles}
                        name="Restaurateur"
                      />
                    }
                    label="Restaurateur"
                  />
                </FormGroup>
              </FormControl>

              {/* {values.roles.map(function (name) {
                return (<Switch test={name} key={name}>
                  <Chip value='Admin' variant="outlined" color="primary" size="small" className="mr-1" label={name} />
                  <Chip value='Restaurateur' variant="outlined" color="secondary" size="small" className="mr-1" label={name} />
                  <Chip default variant="outlined" color="secondary" size="small" className="mr-1" label={name} />
                </Switch>)
              })} */}
              {/* <ToggleButtonGroup value={roles} onChange={handleRoles} aria-label="text formatting">
                <ToggleButton value="Admin">Admin</ToggleButton>
                <ToggleButton value="Restaurateur">Restaurateur</ToggleButton>
              </ToggleButtonGroup> */}
            </Grid>
          </Grid>
        </CardContent>
        {/* <Divider /> */}
        <CardActions>
          <Button color="primary" variant="contained" hidden>
            Save details
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};

UserDetails.propTypes = {
  className: PropTypes.string,
};

export default UserDetails;
