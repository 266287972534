import React, { useEffect, useRef } from "react";
import useTimeout from "@rooks/use-timeout";
import "./NavMenu.css";
import {
  faPizzaSlice,
  faHamburger,
  faBeer,
  faCheese,
  faHotdog,
  faCocktail,
  faPepperHot,
  faIceCream,
  faCoffee,
  faFish,
  faCookie,
  faCookieBite,
  faDrumstickBite,
  faBacon,
  faCandyCane,
  faUtensils,
  faGlassMartini,
  faGlassMartiniAlt,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function NavMenu() {
  const _foodIcons = [
    faPizzaSlice,
    faHamburger,
    faBeer,
    faCheese,
    faHotdog,
    faCocktail,
    faPepperHot,
    faIceCream,
    faCoffee,
    faFish,
    faCookie,
    faCookieBite,
    faDrumstickBite,
    faBacon,
    faCandyCane,
    faUtensils,
    faGlassMartini,
    faGlassMartiniAlt,
  ];
  let _currentFoodIconIndex = useRef(0);
  let _currentFoodIcon = useRef(_foodIcons[_currentFoodIconIndex.current]);

  const changeFoodIcon = () => {
    _currentFoodIcon.current = _foodIcons[_currentFoodIconIndex.current];
    if (_currentFoodIconIndex.current === _foodIcons.length - 1)
      _currentFoodIconIndex.current = 0;
    else _currentFoodIconIndex.current = _currentFoodIconIndex.current + 1;
  };

  const { start } = useTimeout(changeFoodIcon, 500);

  useEffect(() => {
    start();
  });

  return (
    <nav className="navbar navbar-light mb-3">
      <a className="navbar-brand" href="/">
        apicfood
        {/* <small className="text-muted ml-1">
          - see what to eat <FontAwesomeIcon icon={_currentFoodIcon.current} />
        </small> */}
      </a>
      <div className="d-none d-sm-block d-sm-none d-md-block">
        <a href="/" className="restaurantMessage ml-4">
          Food
        </a>
        <a href="/about" className="restaurantMessage ml-4">
          About
        </a>
        <a href="/locations" className="restaurantMessage ml-4">
          Locations
        </a>
        <a href="/app" className="restaurantMessage ml-4">
          App
        </a>
      </div>
      <div className="d-block d-sm-none d-none d-sm-block d-md-none">
        <a href="/app" className="restaurantMessage ml-4">
          download app
        </a>
      </div>
    </nav>
  );
}
