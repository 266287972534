import React, { useState, useRef, useEffect } from "react";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import useOnclickOutside from "react-cool-onclickoutside";
import { useScript } from "./useScript";

const PlacesAutocomplete = ({ setCoordinates, setLocation, initLocation }) => {
  const [currIndex, setCurrIndex] = useState(null);
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here */
    },
    debounce: 300,
    callbackName: "initMap",
  });
  let cachedVal = "";
  const hasSuggestions = status === "OK";
  const acceptedKeys = [38, 40, 13, 27];

  useScript(
    "https://maps.googleapis.com/maps/api/js?key=AIzaSyAf3-ELUBDrJNHo8sxjgkwfijqDfX6bv0c&libraries=places&callback=initMap"
  );
  const ref = useRef();
  useOnclickOutside(ref, () => {
    clearSuggestions();
  });

  const handleInput = (e) => {
    setValue(e.target.value);
    setCoordinates(undefined);
    setLocation("");
  };

  const handleSelect = ({ description }) => () => {
    setValue(description, false);
    setLocation(description);
    clearSuggestions();

    getGeocode({ address: description })
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        setCoordinates({ lat, lng });
      })
      .catch((error) => {
        console.log("Error: ", error);
      });
  };

  const dismissSuggestions = () => {
    setCurrIndex(null);
    clearSuggestions();
  };

  const handleKeyDown = (e) => {
    if (!hasSuggestions || !acceptedKeys.includes(e.keyCode)) return;

    if (e.keyCode === 13 || e.keyCode === 27) {
      dismissSuggestions();
      return;
    }

    let nextIndex;

    if (e.keyCode === 38) {
      e.preventDefault();
      nextIndex = currIndex !== null ? currIndex : data.length;
      nextIndex = nextIndex > 0 ? nextIndex - 1 : null;
    } else {
      nextIndex = currIndex !== null ? currIndex : -1;
      nextIndex = nextIndex < data.length - 1 ? nextIndex + 1 : null;
    }

    setCurrIndex(nextIndex);
    setValue(data[nextIndex] ? data[nextIndex].description : cachedVal, false);
    setLocation(data[nextIndex].description);
    getGeocode({ address: data[nextIndex].description })
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        setCoordinates({ lat, lng });
      })
      .catch((error) => {
        console.log("Error: ", error);
      });
  };

  useEffect(() => {
    setValue(initLocation, false);
    // clearSuggestions();
  }, [initLocation]);

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <li
          key={id}
          onClick={handleSelect(suggestion)}
          className="list-group-item"
        >
          <strong>{main_text}</strong> <small>{secondary_text}</small>
        </li>
      );
    });
  return (
    <div ref={ref}>
      <input
        value={value}
        onChange={handleInput}
        onKeyDown={handleKeyDown}
        disabled={!ready}
        placeholder="London, Paris, New York"
        className="form-control"
      />
      {status === "OK" && (
        <ul
          className="list-group"
          style={{ zIndex: 100, position: "absolute" }}
        >
          {renderSuggestions()}
        </ul>
      )}
    </div>
  );
};

export default PlacesAutocomplete;
