import React, { useState, useEffect } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { useAuth } from "../../context/Auth";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import { useParams } from "react-router";

import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField,
  Chip,
} from "@material-ui/core";

const useStyles = makeStyles(() => ({
  root: {},
}));

const PostDetails = (props) => {
  let { id } = useParams();
  const { authToken } = useAuth();
  const { className, staticContext, ...rest } = props;
  const [postId] = useState(id);
  const classes = useStyles();
  const [values, setValues] = useState({
    id: "",
    name: "",
    restaurant: {
      address: "",
      city: "",
      country: "",
      createdOn: "",
      fullAddress: "",
      id: "",
      instagram: "",
      location: { coordinates: [], type: "Point" },
      name: "",
      postalCode: "",
      telephone: "",
      slug: "",
    },
    postedAt: "1970-01-01T00:00:00.000Z",
    tags: [],
    postalCode: "",
    createdBy: { username: "" },
    location: { coordinates: [0, 0] },
    pathToImage: "",
    hashtags: [],
    slug: "",
    description: "",
  });

  const fetchPost = async () => {
    var url = `${process.env.REACT_APP_API_URL}/posts/getpost/${postId}`;
    fetch(url, {
      method: "GET",
      headers: { Authorization: `Bearer ${authToken}` },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setValues(data);
      });
  };

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  useEffect(() => {
    fetchPost();
    return () => {
      setValues({});
    };
  }, []);

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <form autoComplete="off" noValidate>
        <CardHeader title="Post" />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Post id"
                margin="dense"
                name="postId"
                onChange={handleChange}
                required
                disabled
                value={values.id}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Name"
                margin="dense"
                name="name"
                onChange={handleChange}
                required
                disabled
                value={values.name}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Slug"
                margin="dense"
                name="slug"
                onChange={handleChange}
                required
                disabled
                value={values.slug}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Description"
                margin="dense"
                name="description"
                onChange={handleChange}
                required
                disabled
                value={values.description}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Restaurant"
                margin="dense"
                name="restaurant"
                onChange={handleChange}
                required
                disabled
                value={values.restaurant.name}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Posted at"
                type="datetime-local"
                margin="dense"
                name="city"
                onChange={handleChange}
                required
                disabled
                value={values.postedAt.slice(0, 16)}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <FormControl
                component="fieldset"
                className={classes.formControl}
                disabled
              >
                <FormLabel component="legend">Tags</FormLabel>
                <FormGroup>
                  <div className={classes.root}>
                    {values.tags.map(function (tag, index) {
                      return (
                        <Chip
                          key={index}
                          value={tag}
                          variant="outlined"
                          color="secondary"
                          size="small"
                          className="mr-1"
                          label={tag}
                        />
                      );
                    })}
                  </div>
                </FormGroup>
              </FormControl>
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Created by"
                margin="dense"
                name="createdBy"
                onChange={handleChange}
                required
                disabled
                value={values.createdBy.username}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Instagram"
                margin="dense"
                name="instagram"
                onChange={handleChange}
                required
                disabled
                value={values.restaurant.instagram}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Address"
                margin="dense"
                name="address"
                onChange={handleChange}
                required
                disabled
                value={values.restaurant.address}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="City"
                margin="dense"
                name="city"
                onChange={handleChange}
                required
                disabled
                value={values.restaurant.city}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Country"
                margin="dense"
                name="country"
                onChange={handleChange}
                required
                disabled
                value={values.restaurant.country}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Postal Code"
                margin="dense"
                name="postalCode"
                onChange={handleChange}
                required
                disabled
                value={values.restaurant.postalCode}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Restaurant Slug"
                margin="dense"
                name="restaurantSlug"
                onChange={handleChange}
                required
                disabled
                value={values.restaurant.slug}
                variant="outlined"
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <FormControl
                component="fieldset"
                className={classes.formControl}
                disabled
              >
                <FormGroup>
                  <div className={classes.root}>
                    {values.hashtags.length > 0 &&
                      values.hashtags.map(function (hashtag, index) {
                        return (
                          <Chip
                            key={index}
                            value={hashtag}
                            variant="outlined"
                            color="secondary"
                            size="small"
                            className="mr-1"
                            label={hashtag}
                          />
                        );
                      })}
                  </div>
                </FormGroup>
              </FormControl>
            </Grid>
            <Grid item md={12} xs={12}>
              <img
                className="card-img-top"
                src={values.pathToImage}
                alt={values.name}
              />
            </Grid>
          </Grid>
        </CardContent>
        {/* <Divider /> */}
        <CardActions>
          <Button color="primary" variant="contained" hidden>
            Save details
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};

PostDetails.propTypes = {
  className: PropTypes.string,
};

export default PostDetails;
