import React, { useState } from "react";
import { Navigate } from "react-router";
// import logoImg from "../img/logo.jpg";
import { Card, Form, Input, Button, Error } from "./AuthForm";
import { useAuth } from "./context/Auth";

export default function Login(props) {
  var referer = "/admin";
  if (
    props !== undefined &&
    props.location !== undefined &&
    props.location.state !== undefined &&
    props.location.state.referer !== undefined
  )
    referer = props.location.state.referer;

  const [, setLoggedIn] = useState(false);
  const [isError, setIsError] = useState(false);
  // const [userName, setUserName] = useState("gconn0");
  // const [password, setPassword] = useState("mfQxqH");
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const { setAuthCallback, isLoggedInFunc } = useAuth();

  function postLogin() {
    var authenticateUrl = `${process.env.REACT_APP_API_URL}/admin/authenticate`;
    fetch(authenticateUrl, {
      method: "POST",
      body: JSON.stringify({ userName, password }),
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => {
        if (response.ok) return response;
        else setIsError(true);
      })
      .then((response) => response.json())
      .then((result) => {
        setAuthCallback(result.token);
        setLoggedIn(true);
      })
      .catch((e) => {
        setIsError(true);
      });
  }

  if (isLoggedInFunc()) {
    return <Navigate to={referer} />;
  }

  return (
    <Card>
      <Form>
        <Input
          type="username"
          value={userName}
          onChange={(e) => {
            setUserName(e.target.value);
          }}
          placeholder="Username"
        />
        <Input
          type="password"
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
          }}
          placeholder="Password"
        />
        {/* <Button type="submit" color="primary" variant="contained" className="mr-1">Sign in</Button> */}
        <Button onClick={postLogin} className="btn-primary">
          Sign In
        </Button>
      </Form>
      {/* <Link to="/signup">Don't have an account?</Link> */}
      {isError && (
        <Error>The username or password provided were incorrect!</Error>
      )}
    </Card>
  );
}
