import React, { useState, useEffect } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { useAuth } from "../../context/Auth";
import { DropzoneArea } from "material-ui-dropzone";
import Autocomplete from "@mui/material/Autocomplete";

import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";

import { Navigate } from "react-router";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

var slugify = require("slugify");

const useStyles = makeStyles(() => ({
  root: {},
}));

const CreatePost = (props) => {
  const { authToken } = useAuth();
  const [restaurantsUsers, setRestaurantsUsers] = useState([]);
  const { className, staticContext, ...rest } = props;
  const classes = useStyles();
  const [file, setFile] = useState();
  const [nowRedirect, setNowRedirect] = useState(false);
  // const [nowCreateNew, setNowCreateNew] = useState(false);
  const [isError, setIsError] = useState(false);
  // const [restaurantUserValue, setRestaurantUserValue] = useState([]);
  const [defaultRestaurants, setDefaultRestaurants] = useState([]);
  const [filterInputValue, setFilterInputValue] = useState("");
  // const [defaultRestaurants, setDefaultRestaurants] = useState([
  //   {
  //     restaurantName: "Turtle Bay - Bath",
  //     restaurantAddress: "11 Bridge St, Bath - BA2 4AS, UK",
  //     restaurantId: "615ad530a52a731e2c92c2ea",
  //     username: "yahya",
  //     userId: "6021be003765b900b40ccd55",
  //   },
  // ]);
  // const [values, setValues] = useState({
  //   name: "Caribbean Curry Katsu",
  //   cuisine: "Carribean",
  //   restaurantsId:
  //     defaultRestaurants.length > 0
  //       ? defaultRestaurants.map((x) => {
  //           return x.restaurantId;
  //         })
  //       : [],
  // restaurantAutocompleteValues:
  // defaultRestaurants.length > 0 ? defaultRestaurants : [],
  //   userId: defaultRestaurants.length > 0 ? defaultRestaurants[0].userId : "",
  //   postImageDownloadFrom:
  //     "https://scontent-lcy1-1.cdninstagram.com/v/t51.2885-15/e15/p480x480/271228424_465521068431892_6639632642964945013_n.jpg?_nc_ht=scontent-lcy1-1.cdninstagram.com&_nc_cat=104&_nc_ohc=ckK00VjjXrcAX_cfhbM&edm=AIQHJ4wBAAAA&ccb=7-4&ig_cache_key=Mjc0NDQxNjk3NTkyNzU4ODgzOQ%3D%3D.2-ccb7-4&oh=00_AT8yP3yIQ4EqYbrNDa81aEjY6WhGJRfwilzKSzsulVJiDw&oe=61DCA316&_nc_sid=7b02f1",
  // });

  const [values, setValues] = useState({
    name: "",
    cuisine: "",
    description: "",
    restaurantsId:
      defaultRestaurants.length > 0
        ? defaultRestaurants.map((x) => {
            return x.restaurantId;
          })
        : [],
    userId: defaultRestaurants.length > 0 ? defaultRestaurants[0].userId : "",
    postImageDownloadFrom: "",
    hashtags: "",
    restaurantAutocompleteValues:
      defaultRestaurants.length > 0 ? defaultRestaurants : [],
  });

  const handleChange = (event) => {
    setAValue(event.target.name, event.target.value);
  };

  const setAValue = (propertyName, propertyValue) => {
    setValues({
      ...values,
      [propertyName]: propertyValue,
    });
  };

  const setThreeValues = (
    property1Name,
    property1Value,
    property2Name,
    property2Value,
    property3Name,
    property3Value
  ) => {
    setValues({
      ...values,
      [property1Name]: property1Value,
      [property2Name]: property2Value,
      [property3Name]: property3Value,
    });
  };

  const handleFileChange = (file) => {
    if (values.name === "" && file.length === 1) {
      setAValue("name", file[0].name.split(".").slice(0, -1).join("."));
    }
    setFile(file);
  };

  const onSubmitCallback = (event) => {
    event.preventDefault();
    createPost(values);
  };

  const createPostAndAddNew = () => {
    createPost(values, true);
  };

  const selectFiltered = () => {
    if (filterInputValue === "") {
      setThreeValues(
        "userId",
        "",
        "restaurantsId",
        [],
        "restaurantAutocompleteValues",
        []
      );
      return;
    }
    const filterValues = restaurantsUsers.filter((restaurantsUser) =>
      restaurantsUser.restaurantName
        .toLowerCase()
        .includes(filterInputValue.toLowerCase())
    );
    if (filterValues.length > 0) {
      setThreeValues(
        "userId",
        filterValues[0].userId,
        "restaurantsId",
        filterValues.map((x) => {
          return x.restaurantId;
        }),
        "restaurantAutocompleteValues",
        filterValues
      );
    } else {
      setThreeValues(
        "userId",
        "",
        "restaurantsId",
        [],
        "restaurantAutocompleteValues",
        []
      );
    }
  };

  const createPost = async (post, redirectToCreateNew = false) => {
    var url = `${process.env.REACT_APP_API_URL}/admin/createpost`;
    const formData = new FormData();
    Object.keys(post).forEach((key) => {
      if (key !== "restaurantAutocompleteValues") {
        formData.append(key, post[key]);

        if (key === "name") {
          formData.append(
            "slug",
            slugify(post[key], { lower: true, strict: true })
          );
        }
      }
    });

    if (file.length > 0) formData.append("Image", file[0]);
    fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      body: formData,
    }).then((response) => {
      if (response.ok) {
        if (redirectToCreateNew) {
          window.location.reload();
        } else setNowRedirect(true);
      } else {
        setIsError(true);
      }
    });
  };

  const fetchRestaurantsUsers = async () => {
    var url = `${process.env.REACT_APP_API_URL}/admin/GetRestaurantsUsers`;
    fetch(url, {
      method: "GET",
      headers: { Authorization: `Bearer ${authToken}` },
    })
      .then((response) => response.json())
      .then((data) => {
        setRestaurantsUsers(data);
      });
  };

  useEffect(() => {
    fetchRestaurantsUsers();
    return () => {
      setRestaurantsUsers([]);
    };
  }, []);

  const cuisines = [
    "Afghan",
    "American",
    "Argentine",
    "Asian",
    "Austrian",
    "Bagels",
    "Bakery",
    "Barbecue",
    "Belgian",
    "Bistro",
    "Brazilian",
    "British",
    "Buffet",
    "Burgers",
    "Cafe",
    "Cajun",
    "Californian",
    "Calzones",
    "Cambodian",
    "Caribbean",
    "Catering",
    "Cheesesteaks",
    "Chicken",
    "Chinese",
    "Chowder",
    "Cocktails",
    "Coffee",
    "Colombian",
    "Contemporary",
    "Continental",
    "Creole",
    "Crepes",
    "Cuban",
    "Czech",
    "Deli",
    "Dim Sum",
    "Diner",
    "Dominican",
    "Donuts",
    "Eastern European",
    "Eclectic",
    "English",
    "Ethiopian",
    "European",
    "Fast Food",
    "Filipino",
    "Fish and Chips",
    "Fondue",
    "French",
    "Frozen Yogurt",
    "Fusion",
    "Gastropub",
    "German",
    "Greek",
    "Grill",
    "Gyros",
    "Haitian",
    "Halal",
    "Hawaiian",
    "Healthy",
    "Hookah Bar",
    "Hot Dogs",
    "Ice Cream",
    "Indian",
    "Indonesian",
    "International",
    "Irish",
    "Israeli",
    "Italian",
    "Japanese",
    "Juices",
    "Korean",
    "Korean Barbeque",
    "Kosher",
    "Latin",
    "Latin American",
    "Lebanese",
    "Malaysian",
    "Mediterranean",
    "Mexican",
    "Middle Eastern",
    "Mongolian",
    "Moroccan",
    "Nepalese",
    "Noodle Bar",
    "Norwegian",
    "Organic",
    "Oysters",
    "Pacific Rim",
    "Pakistani",
    "Pan Asian",
    "Pasta",
    "Pasteries",
    "Persian",
    "Peruvian",
    "Pho",
    "Pizza",
    "Polish",
    "Polynesian",
    "Portuguese",
    "Pub Food",
    "Puerto Rican",
    "Ribs",
    "Salad",
    "Salvadoran",
    "Sandwiches",
    "Seafood",
    "Singaporean",
    "Smoothies",
    "Soul Food",
    "Soup",
    "South American",
    "South Pacific",
    "Southern",
    "Southwestern",
    "Spanish",
    "Steak",
    "Subs",
    "Sushi",
    "Taiwanese",
    "Tapas",
    "Tea",
    "Tex Mex",
    "Thai",
    "Tibetan",
    "Traditional",
    "Turkish",
    "Ukrainian",
    "Vegan",
    "Vegetarian",
    "Venezuelan",
    "Venusian",
    "Vietnamese",
    "Wings",
    "Wraps",
  ];

  if (nowRedirect) {
    return <Navigate to="/admin/posts" />;
  } else {
    return (
      <Card {...rest} className={clsx(classes.root, className)}>
        <form autoComplete="off" onSubmit={onSubmitCallback}>
          <CardHeader title="Create Post" />
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Name"
                  margin="dense"
                  name="name"
                  onChange={handleChange}
                  required
                  value={values.name}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Autocomplete
                  id="combo-box-demo"
                  options={cuisines}
                  // getOptionLabel={(option) => option}
                  required
                  fullWidth
                  value={values.cuisine}
                  onChange={(event, newValue) => {
                    setAValue("cuisine", newValue);
                  }}
                  name="cuisine"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Cuisine"
                      variant="outlined"
                      margin="dense"
                      name="cuisine"
                      value={values.cuisine}
                      // onChange={handleChange}
                      required
                    />
                  )}
                />
              </Grid>
              <Grid item md={11} xs={11}>
                <Autocomplete
                  id="restaurant-users-selection"
                  value={values.restaurantAutocompleteValues}
                  options={restaurantsUsers}
                  getOptionLabel={(option) =>
                    `${option.restaurantId} - ${option.restaurantName}`
                  }
                  isOptionEqualToValue={(option, value) => {
                    return option.restaurantId === value.restaurantId;
                  }}
                  defaultValue={defaultRestaurants}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {`${option.restaurantName} - ${option.restaurantAddress} - ${option.username}`}
                    </li>
                  )}
                  required
                  fullWidth
                  multiple
                  freeSolo
                  // filterSelectedOptions
                  onInputChange={(event, value) => {
                    setFilterInputValue(value);
                  }}
                  onChange={(event, newValue, reason) => {
                    if (newValue.length > 0) {
                      setThreeValues(
                        "userId",
                        newValue[0].userId,
                        "restaurantsId",
                        newValue.map((x) => {
                          return x.restaurantId;
                        }),
                        "restaurantAutocompleteValues",
                        newValue
                      );
                    } else {
                      setThreeValues(
                        "userId",
                        "",
                        "restaurantsId",
                        [],
                        "restaurantAutocompleteValues",
                        []
                      );
                    }
                  }}
                  name="restaurant"
                  renderInput={(params) => (
                    <>
                      <TextField
                        {...params}
                        label="Restaurant"
                        variant="outlined"
                        margin="dense"
                      />
                    </>
                  )}
                />
              </Grid>

              <Grid item md={1} xs={1}>
                <Button
                  // margin="dense"
                  // color="primary"
                  // variant="contained"
                  // type="Submit"
                  onClick={() => {
                    selectFiltered();
                  }}
                >
                  Filtered
                </Button>
              </Grid>
              <Grid item md={12} xs={12}>
                <TextField
                  fullWidth
                  label="Description"
                  margin="dense"
                  name="description"
                  onChange={handleChange}
                  value={values.description}
                  variant="outlined"
                  multiline
                  rows={3}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Created by"
                  margin="dense"
                  name="userId"
                  // onChange={handleChange}
                  // required
                  disabled
                  value={values.userId}
                  variant="outlined"
                />
              </Grid>
              {/* <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Restaurant Id"
                  margin="dense"
                  name="restaurantId"
                  // onChange={handleChange}
                  // required
                  disabled
                  value={values.restaurantId}
                  variant="outlined"
                />
              </Grid> */}
              <Grid item md={12} xs={12}>
                <TextField
                  fullWidth
                  label="Hashtags"
                  margin="dense"
                  name="hashtags"
                  onChange={handleChange}
                  // required
                  value={values.hashtags}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <TextField
                  fullWidth
                  label="URL"
                  margin="dense"
                  name="postImageDownloadFrom"
                  onChange={handleChange}
                  // required
                  value={values.postImageDownloadFrom}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <DropzoneArea
                  onChange={handleFileChange}
                  showPreviews={false}
                  acceptedFiles={[
                    "image/jpeg",
                    "image/png",
                    "image/bmp",
                    "image/jpg",
                  ]}
                  filesLimit={1}
                  showFileNames={true}
                />
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <CardActions>
            <Button color="primary" variant="contained" type="Submit">
              Create
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                createPostAndAddNew();
              }}
            >
              Create + Add New
            </Button>
          </CardActions>
        </form>
        {isError && <span>Something went wrong</span>}
      </Card>
    );
  }
};

CreatePost.propTypes = {
  className: PropTypes.string,
};

export default CreatePost;
