import React, { useState, useRef, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { useQueryState } from "react-router-use-location-state";
import GridSearch from "./components/GridSearchToolbar";
import PostsTable from "./components/PostsTable";
import { useAuth } from "./../context/Auth";
import { SearchContext } from "./contexts/SearchContext";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
}));

export default function Posts() {
  const { authToken } = useAuth();
  const classes = useStyles();
  const [posts, setPosts] = useState([]);
  const [totalPosts, setTotalPosts] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [fetchNow, setFetchNow] = useState(true);

  const [searchQuery, setSearchQuery] = useQueryState("search", "");
  const [searchCriteria, setSearchCriteria] = useState(searchQuery);

  const changePage = (newPageNumber) => {
    setPageNumber(newPageNumber);
  };

  const changePageSize = (pageSize) => {
    setPageSize(pageSize);
  };

  const onSearch = () => {
    setSearchCriteria(searchCriteria);
    setSearchQuery(searchCriteria);
    setFetchNow(!fetchNow);
    setPageNumber(0);
  };

  const onSearchChange = (searchedCriteria) => {
    setSearchCriteria(searchedCriteria);
  };

  const onPostDeleted = (postId) => {
    var url = `${process.env.REACT_APP_API_URL}/admin/DeletePost/${postId}`;
    fetch(url, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    }).then(() => {
      setFetchNow(!fetchNow);
    });
  };

  const fetchPosts = async () => {
    var queryString = window.location.search;
    var url = `${process.env.REACT_APP_API_URL}/admin/getposts${queryString}`;
    var queryPart = queryString === "" ? "?" : "&";
    url = url + queryPart + "page=" + pageNumber + "&size=" + pageSize;
    fetch(url, {
      method: "GET",
      headers: { Authorization: `Bearer ${authToken}` },
    })
      .then((response) => response.json())
      .then((data) => {
        setPosts(data.items);
        setTotalPosts(data.totalItems);
      });
  };

  useEffect(() => {
    fetchPosts();
    return () => {
      setPosts([]);
    };
  }, [fetchNow, pageSize, pageNumber]);

  return (
    <div className={classes.root}>
      <SearchContext.Provider
        value={{
          search: searchCriteria,
          onSearchChangeCallback: onSearchChange,
          onSearchCallback: onSearch,
          placeholder: "Search Posts",
        }}
      >
        <GridSearch />
      </SearchContext.Provider>
      <div className={classes.content}>
        <PostsTable
          posts={posts}
          postsCount={totalPosts}
          changePageCallback={changePage}
          changePageSizeCallback={changePageSize}
          page={pageNumber}
          rowsPerPage={pageSize}
          onDeletePostCallback={onPostDeleted}
        />
      </div>
    </div>
  );
}
