import React from "react";
import { Container } from "reactstrap";
import NavMenu from "./NavMenu";

import {
  faFacebook,
  faTwitter,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function Layout(props) {
  return (
    <div>
      <NavMenu />
      <Container>{props.children}</Container>
      <footer>
        <div className="container-fluid footer-basic">
          <div className="row ">
            <div className="col-lg-6 col-sm-12 col-xs-12 copyright text-center-xs text-center-sm text-center-md text-right-lg d-none d-sm-block d-sm-none d-md-block">
              <a href="/">apicfood</a> © 2022
            </div>
            <div className="col-lg-6 col-sm-12 col-xs-12 text-center-xs text-center-sm text-center-md text-left-lg">
              <div className="social">
                <a
                  href="https://twitter.com/apicfood"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon icon={faTwitter} />
                </a>
                <a
                  href="https://www.facebook.com/apicfood"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon icon={faFacebook} />
                </a>
                <a
                  href="https://www.instagram.com/official_apicfood/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon icon={faInstagram} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}
