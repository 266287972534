import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Grid } from "@material-ui/core";
// import { useAuth } from "./../context/Auth";
import TotalUsers from "./components/TotalUsers";
import TotalRestaurants from "./components/TotalRestaurants";
import TotalPosts from "./components/TotalPosts";
import StatsContextProvider from "./contexts/StatsContext";
import TotalScraped from "./components/TotalScraped";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
}));

const Dashboard = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <StatsContextProvider>
        <Grid container spacing={4}>
          <Grid item lg={4} sm={6} xl={3} xs={12}>
            <TotalUsers />
          </Grid>
          <Grid item lg={4} sm={6} xl={3} xs={12}>
            <TotalRestaurants />
          </Grid>
          <Grid item lg={4} sm={6} xl={3} xs={12}>
            <TotalPosts />
          </Grid>
          <Grid item lg={4} sm={6} xl={3} xs={12}>
            <TotalScraped />
          </Grid>
        </Grid>
      </StatsContextProvider>
    </div>
  );
};

export default Dashboard;
