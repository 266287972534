import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import PerfectScrollbar from "react-perfect-scrollbar";
import { makeStyles } from "@material-ui/styles";
import EditOutlined from "@material-ui/icons/EditOutlined";
import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
  Button,
  IconButton,
  Chip,
} from "@material-ui/core";

// import { getInitials } from 'helpers';

const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    padding: 0,
  },
  inner: {
    minWidth: 1050,
  },
  nameContainer: {
    display: "flex",
    alignItems: "center",
  },
  // avatar: {
  //   marginRight: theme.spacing(2)
  // },
  actions: {
    justifyContent: "flex-end",
  },
}));

const UsersTable = (props) => {
  const {
    className,
    users,
    usersCount,
    changePageCallback,
    changePageSizeCallback,
    page,
    rowsPerPage,
    ...rest
  } = props;

  const classes = useStyles();

  const handlePageChange = (event, page) => {
    // setPage(page);
    changePageCallback(page);
  };

  const handleRowsPerPageChange = (event) => {
    // setRowsPerPage(event.target.value);
    changePageSizeCallback(event.target.value);
  };

  const Switch = (props) => {
    const { test, children } = props;
    var found = children.find((child) => {
      return child.props.value === test;
    });

    if (found != undefined) return found;

    return children.find((child) => {
      return child.props.default !== null;
    });
  };

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>Username</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Roles</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users.slice(0, rowsPerPage).map((user) => (
                  <TableRow className={classes.tableRow} hover key={user.id}>
                    <TableCell>
                      <IconButton
                        size="small"
                        aria-label="edit"
                        href={`/admin/users/${user.id}/${user.username}`}
                      >
                        <EditOutlined />
                      </IconButton>
                      {/* <IconButton size="small" aria-label="delete"><DeleteOutline /></IconButton> */}
                    </TableCell>
                    <TableCell>{user.username}</TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>
                      <div className={classes.root}>
                        {user.roles.map(function (name) {
                          return (
                            <Switch test={name} key={name}>
                              <Chip
                                value="Admin"
                                variant="outlined"
                                color="primary"
                                size="small"
                                className="mr-1"
                                label={name}
                              />
                              <Chip
                                value="Restaurateur"
                                variant="outlined"
                                color="secondary"
                                size="small"
                                className="mr-1"
                                label={name}
                              />
                              <Chip
                                default
                                variant="outlined"
                                color="secondary"
                                size="small"
                                className="mr-1"
                                label={name}
                              />
                            </Switch>
                          );
                        })}
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={usersCount}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </CardActions>
    </Card>
  );
};

UsersTable.propTypes = {
  className: PropTypes.string,
  users: PropTypes.array.isRequired,
};

export default UsersTable;
