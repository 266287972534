import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import PerfectScrollbar from "react-perfect-scrollbar";
import { makeStyles } from "@material-ui/styles";
import EditOutlined from "@material-ui/icons/EditOutlined";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import TimeAgo from "react-timeago";

import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  IconButton,
  Chip,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    padding: 0,
  },
  inner: {
    minWidth: 1050,
  },
  nameContainer: {
    display: "flex",
    alignItems: "center",
  },
  actions: {
    justifyContent: "flex-end",
  },
}));

const PostsTable = (props) => {
  const {
    className,
    posts,
    postsCount,
    changePageCallback,
    changePageSizeCallback,
    page,
    rowsPerPage,
    onDeletePostCallback,
    ...rest
  } = props;

  const classes = useStyles();

  const handlePageChange = (event, page) => {
    changePageCallback(page);
  };

  const handleRowsPerPageChange = (event) => {
    changePageSizeCallback(event.target.value);
  };

  const onDeletePost = (id) => {
    onDeletePostCallback(id);
  };

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <IconButton
                      size="small"
                      aria-label="add"
                      href="/admin/createpost/"
                    >
                      <AddCircleOutlineOutlinedIcon />
                    </IconButton>
                    {/* <IconButton
                      size="small"
                      aria-label="add"
                      href="/admin/createpost/"
                    >
                      <PlaylistAddIcon />
                    </IconButton> */}
                  </TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Restaurant</TableCell>
                  <TableCell>At</TableCell>
                  <TableCell>Tags</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {posts.slice(0, rowsPerPage).map((post) => (
                  <TableRow className={classes.tableRow} hover key={post.id}>
                    <TableCell
                      style={{
                        whiteSpace: "nowrap",
                      }}
                    >
                      <IconButton
                        size="small"
                        aria-label="edit"
                        href={`/admin/posts/${post.id}`}
                      >
                        <EditOutlined />
                      </IconButton>
                      <IconButton
                        size="small"
                        aria-label="delete"
                        onClick={(e) => onDeletePost(post.id)}
                      >
                        <DeleteOutline />
                      </IconButton>
                    </TableCell>
                    <TableCell>{post.name}</TableCell>
                    <TableCell>{post.restaurant.name}</TableCell>
                    <TableCell>
                      <TimeAgo date={post.postedAt} />
                    </TableCell>
                    <TableCell>
                      <div className={classes.root}>
                        {post.tags.map(function (name, index) {
                          return (
                            <Chip
                              key={index}
                              value={name}
                              variant="outlined"
                              color="secondary"
                              size="small"
                              className="mr-1"
                              label={name}
                            />
                          );
                        })}
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={postsCount}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </CardActions>
    </Card>
  );
};

PostsTable.propTypes = {
  className: PropTypes.string,
  posts: PropTypes.array.isRequired,
};

export default PostsTable;
